<template>
    <div id="signature-details-target">
        <div class="application-section-title">{{ $t('APPLICATION.SIGNATURE.SECTION_TITLE') }}</div>
        <b-card class="st-section mt-6 signature-section">
            <div class="st-section-body">
                <div class="signature-section-title">{{ $t('APPLICATION.SIGNATURE.TITLE') }}</div>
                <div class="signature-help">
                    <p class="signature-help-text">{{ $t('APPLICATION.SIGNATURE.ONLINE.HELP_TEXT') }}</p>
                    <p class="signature-help-text">{{ $t('APPLICATION.SIGNATURE.OFFLINE.HELP_TEXT') }}</p>
                </div>
                <div class="switch-button">
                    <button
                        type="button"
                        class="switch-button-item"
                        :disabled="isPaid || isSigned"
                        @click.stop="selectSignatureType(true)"
                        :class="[showOnlineSign ? 'active' : '']"
                    >
                    {{ $t('APPLICATION.SIGNATURE.TABS.ONLINE') }}
                    </button>
                    <button
                        type="button"
                        class="switch-button-item"
                        :disabled="isPaid || isSigned"
                        @click.stop="selectSignatureType(false)"
                        :class="[!showOnlineSign ? 'active' : '']"
                    >
                    {{ $t('APPLICATION.SIGNATURE.TABS.OFFLINE') }}
                    </button>
                </div>
            </div>
            <div class="mt-8">
                <signature-online
                    v-if="showOnlineSign"
                    :viewMode="viewMode"
                    @update="updateCertSignPackage"
                    :signDetails="signDetails"
                    @validatePackage="validatePackage"
                    ></signature-online >
                <signature-offline @downloadFiles="downloadFiles"  v-else></signature-offline>
            </div>
        </b-card>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers('applications/form');
import { DO_SET_SIGN_DETAILS } from '@/modules/applications/store/applications-form-store';

export default {
  name: "SignatureDetails",
  components: {
      SignatureOnline: () => import('@/modules/applications/components/signature/SignatureOnline'),
      SignatureOffline: () => import('@/modules/applications/components/signature/SignatureOffline'),
  },
  props: {
      signDetails: {
          type: Object,
          required: false
      },
      viewMode: {
          type: Boolean,
          required: false,
      },
  },
  data() {
    return {
        showOnlineSign: true,
        isSigned: false,
        isPaid: false,
    };
  },
  computed: {
    ...mapGetters(['record']),
  },
  methods: {
      ...mapActions([DO_SET_SIGN_DETAILS]),
      selectSignatureType(isOnline) {
        this.showOnlineSign = isOnline;
        this.$emit('updateSignatureType', this.showOnlineSign);
        this[DO_SET_SIGN_DETAILS]({ isOnline });
      },
      updateCertSignPackage(item) {
          this.$emit('updateCertSignPackage', item);
          this[DO_SET_SIGN_DETAILS]({ package: item });
      },
      downloadFiles() {
          this.$emit('downloadFiles');
      },
      validatePackage(need) {
          this.$emit('validatePackage', need);
      },
  },
  created() {
      if (this.signDetails) {
          this.showOnlineSign = this.signDetails.isOnline;
      }
      if (this.record) {
          this.isSigned = this.record.is_signed;
          this.isPaid = this.record.is_paid;
      }
  },
};
</script>
