<template>
  <div id="viewDiv" class="mb-8"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Map from "@arcgis/core/Map";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import Polygon from "@arcgis/core/geometry/Polygon";
import Point from "@arcgis/core/geometry/Point";
import WebStyleSymbol from "@arcgis/core/symbols/WebStyleSymbol";
import * as projection from "@arcgis/core/geometry/projection";
import * as promiseUtils from "@arcgis/core/core/promiseUtils";
import esriConfig from "@arcgis/core/config.js";
import esriConfigJson from "@/config/esri.config.json";

export default {
  name: "StMap",
  props: {
    showLayers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleLayers: {},
    };
  },
  computed: {
    ...mapGetters({
      uatIdsFromRealEstates: "applications/form/getUatIdsFromRealEstates",
    }),
  },
  mounted() {
    this.loadMap([]);
  },
  methods: {
    ...mapActions({
      getLayersByRealEstateUatIds:
        "applications/form/getLayersByRealEstateUatIds",
      getGisToken: "applications/form/getGisToken",
    }),
    async loadMap(parcels) {
      esriConfig.apiKey = esriConfigJson.apiKey;
      const layers = await this.getGisLayers();
      const map = new Map({
        basemap: "arcgis-topographic",
        layers,
      });
      // assign map to this view
      const view = new MapView({
        container: this.$el,
        map: map,
        center: [23.45, 46.845],
        zoom: 10.5,
        navigation: {
          mouseWheelZoomEnabled: false,
        },
      });

      const simpleFillSymbol = {
        type: "simple-fill",
        color: [227, 139, 79, 0.8], // Orange, opacity 80%
        outline: {
          color: [255, 255, 255],
          width: 1,
        },
      };

      const popupTemplate = {
        title: "{Name}",
        content: "{Description}",
      };

      // can be used  for pin
      const symbol = {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        style: "square",
        color: "blue",
        size: "8px", // pixels
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: [255, 255, 0],
          width: 3, // points
        },
      };

      let centerPointX = 0;
      let centerPointY = 0;

      if (parcels.length) {
        view
          .when()
          .then(getGraphics)
          .then(getFeaturesFromPromises)
          .then(createLayer)
          .then(addToView)
          .then(zoomAndCenter)
          .catch((error) => {
            // For internal usage
            console.error("The view's resources failed to load: ", error);
          });

        function getGraphics() {
          const graphicPromises = [];
          parcels.forEach((item) => {
            const { coordinates, cadastral_number } = item;

            const attributes = {
              Name: cadastral_number,
              Description: item.text,
            };

            const polygonRings = [];
            coordinates.forEach((el) => {
              polygonRings.push([el.x, el.y]);
            });

            const polygon = new Polygon({
              type: "polygon",
              rings: polygonRings,
              spatialReference: {
                wkid: 31700,
              },
            });

            const polygonGraphicPromise = polygonToGraphic(polygon, attributes);
            graphicPromises.push(polygonGraphicPromise);

            const textSymbol = {
              type: "text", // autocasts as new TextSymbol()
              color: "black",
              text: cadastral_number,
            };

            const pointText = {
              //Create a point
              type: "point",
              longitude: polygon.centroid.x,
              latitude: polygon.centroid.y,
              spatialReference: {
                wkid: 31700,
              },
            };

            centerPointX = polygon.centroid.x;
            centerPointY = polygon.centroid.y;

            const pointTextGraphicPromise = pointToGraphic(
              pointText,
              textSymbol
            );
            graphicPromises.push(pointTextGraphicPromise);
          });
          return promiseUtils.eachAlways(graphicPromises);
        }

        // Filters only promises that resolve with valid values (a graphic
        // in this case) and resolves them as an array of graphics.
        function getFeaturesFromPromises(eachAlwaysResponses) {
          return eachAlwaysResponses
            .filter((graphicPromise) => {
              return graphicPromise.value;
            })
            .map((graphicPromise) => {
              return graphicPromise.value;
            });
        }

        function createLayer(graphics) {
          return new GraphicsLayer({
            graphics,
          });
        }

        function addToView(layer) {
          view.map.add(layer);
        }

        function zoomAndCenter() {
          projection
            .load()
            .then(() => {
              let opts = {
                duration: 3000, // Duration of animation will be 3 seconds
              };

              let zoomPointStereo70 = new Point({
                x: centerPointX,
                y: centerPointY,
                spatialReference: 31700,
              });

              //default spatial projection
              let outSpatialReference = new SpatialReference({
                wkid: 102100, //Sphere_Sinusoidal projection
              });

              let defaultSpatialProjectionZoomPoint = projection.project(
                zoomPointStereo70,
                outSpatialReference
              );

              // go to point at LOD 15 with custom duration
              view.goTo(
                {
                  target: defaultSpatialProjectionZoomPoint,
                  zoom: 18,
                },
                opts
              );
            })
            .catch((error) => {
              // For internal use
              console.error("Failed to load projection ", error);
            });
        }

        function polygonToGraphic(geo, attr) {
          return promiseUtils.create((resolve) => {
            resolve(
              new Graphic({
                geometry: geo,
                symbol: simpleFillSymbol,
                attributes: attr,
                popupTemplate: popupTemplate,
              })
            );
          });
        }

        function pointToGraphic(geo, symbolItem) {
          return promiseUtils.create((resolve) => {
            resolve(
              new Graphic({
                geometry: geo,
                symbol: symbolItem,
              })
            );
          });
        }
      }
    },
    async getGisLayers() {
      if (!this.showLayers) return [];
      const layers = await this.getLayersByRealEstateUatIds(
        this.uatIdsFromRealEstates
      );
      const { token } = await this.getGisToken();
      const visibleLayers = [];
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        const feature_service_url = layer.feature_service_url;
        esriConfig.request.interceptors.push({
        urls: feature_service_url,
        before: function (params) {
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.token = token;
        },
      });
        for (let j = layer.sublayers.length - 1; j >= 0; j--) {
          const featureLayer = new FeatureLayer({
            url: `${feature_service_url}`,
            layerId: j,
          });
          visibleLayers.push(featureLayer);
        }
      }
      return visibleLayers;
    },
  },
};
</script>

<style>
/* esri styles */
@import url("https://js.arcgis.com/4.20/esri/themes/light/main.css");

#viewDiv {
  height: inherit;
}
</style>
