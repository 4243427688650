<template>
    <b-form
        class="form"
        novalidate="novalidate"
        id="st_application_form"
        ref="applicationDetailsTab"
    >
        <div class="row">
            <div :class="sectionClass">
                <generate-form
                    v-if="formSchema"
                    :data="formSchema"
                    :value="formModels"
                    :viewMode="viewMode || isPaid || isSigned"
                    ref="generateForm"
                    @child-mounted="generatedFormMounted"
                    @modelUpdated="updateFormData"
                ></generate-form>
            </div>

            <div :class="sectionClass">
                <territorial-atlas
                    v-if="showTerriotrialAtlas"
                    v-show="formSchema"
                    :realEstates="realEstatesList"
                    :viewMode="viewMode"
                    ref="territorialAtlas"
                ></territorial-atlas>

                <st-section :header="$t('APPLICATION.VIEW.ATTACHMENTS_DETAILS_SECTION_TITLE')" v-if="viewMode">
                    <template #body>
                        <div class="row" v-if="files.length">
                            <div class="col-12" v-for="file in files" :key="file.id">
                                <span>
                                    {{ file.filename }}
                                    <st-button
                                        :callback="() => downloadFile(file.id)"
                                        variant="light"
                                        class="st-btn-text-primary"
                                    >
                                    <i class="fas fa-download"></i>
                                        {{$t('GENERAL.ACTIONS.DOWNLOAD')}}
                                    </st-button>
                                </span>
                            </div>
                        </div>
                        <div class="row" v-if="!files.length">{{$t('GENERAL.TABLE_EMPTY_TEXT')}}</div>
                    </template>
                </st-section>
            </div>
        </div>
        <div>
            <signature-details
                v-if="isDraft && !isLoading"
                v-can:edit="'application'"
                :viewMode="isPaid"
                :signDetails="signDetails"
                @updateSignatureType="updateSignatureType"
                @updateCertSignPackage="updateCertSignPackages"
                @downloadFiles="downloadPdfFile"
                ref="signatureDetails"
            ></signature-details>
            <application-summary
                :viewMode="viewMode"
                :formModels="formModels"
                :signDetails="signDetails"
                ref="applicationSummary"
                @child-mounted="applicationSummaryMounted"
            ></application-summary>
        </div>
    </b-form>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from "vuex";
import GenerateForm from '@/modules/forms/components/generate-form/GenerateForm.vue';
import SignatureDetails from "@/modules/applications/components/signature/SignatureDetails";
import ApplicationSummary from "@/modules/applications/components/summary/ApplicationSummary";
import TerritorialAtlas from "@/modules/applications/components/territorial-atlas/TerritorialAtlasCard";
import { axiosInlineDownload } from '@/modules/applications/utils/axios-inline-downloader';

export default {
    name: "ApplicationDetailsTab",
    components: {
        GenerateForm,
        SignatureDetails,
        ApplicationSummary,
        TerritorialAtlas,
    },
    props: {
        applicationId: String,
    },
    data() {
        return {
            files: [],
            formSchema: null,
            formModels: {},
            fileStatus: {
                DRAFT: 'draft',
                SIGNED: 'signed',
                GENERATED: 'generated',
                RESOLVED: 'resolved'
            },
            applicationDraftStatus: 'draft'
        }
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            fileToken: "applications/form/fileToken",
            applications: "applications/list/rows",
            loading: "shared/loading",
            downloadToken: 'applications/form/fileToken',
        }),
        viewMode() {
            return this.record?.status !== this.applicationDraftStatus;
        },
        isLoading() {
            return this.loading["applications/update"] || this.loading["applications/find"];
        },
        sectionClass(){
            return (this.isDraft) ? 'col-12' : 'col-6';
        },
        isPaid() {
            return this.record?.is_paid;
        },
        isSigned() {
            return this.record?.is_signed;
        },
        isDraft() {
            return this.record?.status === this.applicationDraftStatus;
        },
        realEstatesList() {
            if (!this.formModels?.real_estate_target?.length) return [];
            return this.formModels.real_estate_target.filter((el) => el?.coordinates?.length);
        },
        signDetails() {
            return this.record?.sign_details ?? {
                isOnline: true,
                package: null
            }
        },
        showTerriotrialAtlas() {
            return !this.viewMode || this.realEstatesList.length;
        },
    },
    methods: {
        ...mapActions({
            findFiles: "applications/form/findFiles",
            doFindApplicationType: 'applications/form/findApplicationType',
            doFindForm: 'applications/form/findForm',
            doUpdate: "applications/form/update",
            initializeOfflineSigning: 'applications/form/initializeOfflineSigning',
            updateOfflineSignDownloadStatus: 'applications/form/updateOfflineSignDownloadStatus',
        }),
        downloadFile(id) {
            window.open(`${window.VUE_APP_API_URL}/files/${id}?token=${this.fileToken}`, '_blank');
        },
        updateSignatureType(isOnline) {
            this.record.sign_details = {
                isOnline,
            }
            this.$emit('updateSignatureType', isOnline);
        },
        updateCertSignPackages(item) {
            this.record.sign_details = {
                ...this.record.sign_details,
                package: item
            }
            this.$emit('updateCertSignPackages', item);
        },
        async downloadArchive() {
            this.$emit('componentEvent', 'downloadArchive');
        },
        generatedFormMounted() {
            this.$emit('form-mounted');
        },
        applicationSummaryMounted() {
            this.$emit('app-summary-mounted');
        },
        updateFormData(data, key) {
            Vue.set(this.formModels, key, data);
        },
        async downloadPdfFile() {
            const filesLocation = 'files';
            const initializeSign = await this.initializeOfflineSigning({
                id: this.applicationId,
                filesLocation
            });

            if (initializeSign?.offlineSign?.files) {
                this.updateOfflineSignDownloadStatus(true);
                const lastFile = initializeSign.offlineSign.files[initializeSign.offlineSign.files.length - 1];
                await axiosInlineDownload({
                    url: `${window.VUE_APP_API_URL}/files/${lastFile.file_id}?token=${this.downloadToken}`,
                    label: lastFile.filename
                });
            }
        },
    },
    async created() {
        if (this.record?.application_type_id) {
            this.formModels = this.record.form;
            this.formModels.application_type_id = this.record.application_type_id;
            const { form_citizen_id } = await this.doFindApplicationType(this.record.application_type_id);
            const formData = await this.doFindForm(form_citizen_id);
            this.formSchema = formData.schema;
        }

        if (this.record?.files?.length && this.fileToken) {
            this.record.files.forEach(file => {
                if (file.file_type === 'input' || (file.file_type === 'output' && file.status === this.fileStatus.SIGNED)) {
                    this.findFiles({id: file.file_id, token: this.fileToken}).then((response) => {
                        response.filename = file.filename;
                        this.files.push(response);
                    });
                }
            });
        }
    },
};
</script>
