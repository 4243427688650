<template>
    <st-section :header="$t('APPLICATION.TERRITORIAL_ATLAS.HEADER')">
        <template #body>
            <p v-if="!viewMode">{{ $t('APPLICATION.TERRITORIAL_ATLAS.SUBTITLE') }}</p>
            <div class="atlas-map-placeholder" v-if="!showMap">
                <span>{{ $t('APPLICATION.TERRITORIAL_ATLAS.PLACEHOLDER') }}</span>
            </div>
            <div class="atlas-map-wrapper" v-show="showMap">
                <st-map
                    ref="atlasMap"
                    :showLayers="showLayers"
                    ></st-map>
            </div>
            <b-row class="mt-6">
                <b-col :cols="colsLeft">
                    <div class="form-group">
                        <label class="mb-4">
                            {{ $t('APPLICATION.TERRITORIAL_ATLAS.REAL_ESTATES_LABEL') }}
                        </label>
                        <b-form-checkbox-group
                            v-model="selectedRealEstates"
                            :options="realEstatesOptions"
                            class="st-application-real-estate-list"
                            text-field="text"
                            value-field="cadastral_number"
                            size="lg"
                            @change="onChangeParcels(selectedRealEstates)"
                        ></b-form-checkbox-group>
                    </div>
                </b-col>
                <b-col :cols="colsRight" class="real-estate-container">
                    <st-button
                        variant="light"
                        customClass="text-primary shadow-sm p-3 mb-5 bg-white rounded"
                        :callback="generateMap"
                        :disabled="!selectedRealEstates.length"
                    >
                        <i class="fas fa-sync-alt text-primary"></i>
                        {{ $t('APPLICATION.TERRITORIAL_ATLAS.GENERATE_BUTTON') }}
                    </st-button>
                </b-col>
            </b-row>
         </template>
    </st-section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StMap from '@/shared/components/StMap.vue';

export default {
    name: 'TerritorialAtlas',
    components: {
        StMap,
    },
    props: {
        realEstates: {
            type: Array,
            default: () => [],
        },
        showLayers: {
            type: Boolean,
            default: false,
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedRealEstates: [],
            showMap: false,
            parcels: [],
            initialCreateLoad: true,
            colsLeft: 10,
            colsRight: 2,
        }
    },
    watch: {
        realEstates: {
            deep: true,
            immediate: true,
            handler(newArray) {
                if (!this.initialCreateLoad || newArray.length) {
                    this.selectedRealEstates = newArray.map((el) => (el.cadastral_number));
                    this.parcels = newArray.map((el) => ({
                        cadastral_number: el.cadastral_number,
                        text: `${el.cadastral_number} ${el.territorial_administrative_unit}`,
                        siruta: el.territorial_administrative_unit,
                        coordinates: el.coordinates,
                    }));
                    this.generateMap();
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            realEstate: 'applications/form/realEstate',
            uatIdsFromRealEstates: 'applications/form/getUatIdsFromRealEstates',
        }),
        realEstatesOptions() {
            return this.realEstates.map((el) => ({
                cadastral_number: el.cadastral_number,
                text: `${el.cadastral_number} ${el.territorial_administrative_unit}`,
                siruta: el.territorial_administrative_unit,
                coordinates: el.coordinates,
            })) || [];
        },
    },
    methods: {
        generateMap() {
            this.initialCreateLoad = false;
            this.showMap = true;
            this.$nextTick(() => {
                this.$refs.atlasMap.loadMap(this.parcels);
            });
        },
        onChangeParcels(checked) {
            this.parcels = this.realEstatesOptions.filter(el=> checked.includes(el.cadastral_number));
        },
    },
    created() {
        this.colsLeft = this.viewMode ? 9 : 10;
        this.colsRight =  this.viewMode ? 3 : 2;
        if (this.realEstates.length || this.viewMode) {
            this.initialCreateLoad = false;
            this.parcels = this.realEstates.map((el) => ({
                cadastral_number: el.cadastral_number,
                text: `${el.cadastral_number} ${el.territorial_administrative_unit}`,
                siruta: el.territorial_administrative_unit,
                coordinates: el.coordinates,
            }));
        }
    },
}
</script>


<style lang="scss" scoped>
    .st-application-real-estate-list ::v-deep{
        .custom-control-inline {
            margin-right: 1.5rem;
        }
        .custom-control-label {
            background-color: #f0f0f0 !important;
            border: 1px solid rgba(60, 60, 60, 0.26);
            border-radius: 4px;
            color: #333;
            line-height: 1.5;
            padding: 0 0.25em;
        }
    }
</style>
