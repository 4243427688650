<template>
    <div class="fixed-tab-nav">
        <loader v-if="showLoader || paymentLoading" :text="offlineSigningText"/>

        <div class="st-wizard-tabs pb-6" :class="{ fixed: scrollPosition > 10 }">
            <b-nav tabs justified align="start" class="st-wizard-tabs-nav" v-b-scrollspy="260">
                <b-nav-item key="pre" class="nav-item-initial" disabled>
                    <div class="d-flex justify-content-center icon-arrow-right">
                        <i class="far fa-file-alt"></i>
                    </div>
                </b-nav-item>
                <b-nav-item
                    :href="tab.id"
                    v-on:click.prevent="goToSection(tab.ref, $event)"
                    v-for="tab in tabs"
                    :key="tab.index"
                    :disabled="tab.disabled"
                    v-show="tab.show"
                >
                    <div class="d-flex justify-content-center w-100">
                        <i :class="tab.icon"></i>
                        <span class="nav-item-text text-uppercase ml-4">{{
                                $t(tab.name)
                            }}</span>
                    </div>
                </b-nav-item>
            </b-nav>
            <st-button
                variant="light"
                :spinner="isLoadingSaveDraft"
                :callback="() => saveAsDraft(true)">
                <i class="fa fa-save"></i>
                {{ $t('APPLICATION.FORM.SAVE_AS_DRAFT_BUTTON') }}
            </st-button>
        </div>
        <div class="navbar-scroll">
            <div id="fake-scroll-top" ref="top"></div>
            <application-details-tab
                v-if="!isLoading"
                :applicationId="id"
                ref="applicationDetails"
                @componentEvent="handleEvent"
                @updateSignatureType="updateSignatureType"
                @updateCertSignPackages="updateCertSignPackages"
                @form-mounted="handleFormMounted"
                @app-summary-mounted="handleAppSummaryMounted"
            >
            </application-details-tab>

            <div class="d-flex align-items-center justify-content-center mt-6" v-if="isDraft">
                <st-button
                    v-if="!isPaid"
                    v-can:edit="'application'"
                    :callback="doPay"
                    :disabled="disablePayment"
                >{{ $t('APPLICATION.FORM.PAY_BUTTON') }}
                </st-button>
                <st-button
                    v-if="isPaid && !isSigned"
                    v-can:edit="'application'"
                    :callback="doSign"
                >{{ $t('APPLICATION.FORM.SIGN_BUTTON') }}
                </st-button>
                <st-button
                    v-if="canApply"
                    v-can:edit="'application'"
                    :callback="doApply"
                >{{ $t('APPLICATION.FORM.APPLY_BUTTON') }}
                </st-button>
            </div>
        </div>
        <confirm-sign-modal
            :applicationId="id"
            ref="confirm-sign-document-modal"
            @signValidated="signValidated"
        />
    </div>
</template>
<script>
import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
import ApplicationDetailsTab from '@/modules/applications/components/view-application/ApplicationDetailsTab';
import { mapActions, mapGetters } from 'vuex';
import Message from '@/shared/message/message';
import TaxesStoreObserverMixin from '@/modules/applications/store/taxes-store-observer-mixin';
import { RE_ENROLL_ERRORS } from '@/modules/applications/utils/constants';
import Errors from '@/shared/error/errors';
import { axiosInlineDownload } from '@/modules/applications/utils/axios-inline-downloader';
import Swal from "sweetalert2";

export default {
    name: 'ApplicationEdit',
    mixins: [TaxesStoreObserverMixin],
    components: {
        ApplicationDetailsTab,
        ConfirmSignModal: () => import('@/modules/applications/components/signature/ConfirmSignModal'),
    },
    props: {
        id: String,
    },
    watch: {
        signValidatedAndApply(newValue, oldValue) {
            if (newValue === oldValue) return false;
            if (!newValue) {
                Swal.close();
            } else {
                this.handleOpenLoader();
            }
        }
    },
    data() {
        return {
            paymentStatus: 'successful',
            signDetails: {
                isOnline: true,
                package: null,
            },
            identificationFilesUploaded: false,
            scrollPosition: null,
            needPackageValidation: true,
            signingLoading: true,
        };
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
            uploadAccessToken: 'applications/form/uploadAccessToken',
            downloadToken: 'applications/form/fileToken',
            signingStatus: 'applications/form/signingStatus',
        }),
        hasPermissionToReadStaff() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        titleComputed() {
            if (!this.id) return this.$t('APPLICATIONS.VIEW.TITLE');

            const applicationTypeName = this.record?.application_type_name;
            if (this.record?.status === 'draft') {
                return applicationTypeName;
            }

            const uid = this.record?.identification_number;

            return `${uid} - ${applicationTypeName}`;
        },
        backLink() {
            return this.id ? {name: 'applications'} : '';
        },
        isLoading() {
            return (
                this.loading['applications/find']
            );
        },
        isLoadingSaveDraft() {
            return (
                this.loading['applications/create'] ||
                this.loading['applications/update']
            );
        },
        isLoadingSign() {
            return this.loading['applications/signDocumentCitizen'];
        },
        paymentLoading() {
            return this.paymentStatus !== 'successful' && this.paymentStatus !== 'failed';
        },
        tabs() {
            return [
                {
                    index: 0,
                    name: 'APPLICATION.TABS.NEW_APPLICATION',
                    ref: 'top',
                    id: '#fake-scroll-top',
                    icon: 'fas fa-edit',
                    show: true,
                },
                {
                    index: 1,
                    name: 'APPLICATION.TABS.BENEFICIARY',
                    ref: 'beneficiaryList',
                    id: '#beneficiary-target',
                    icon: 'fas fa-users',
                    show: true,
                },
                {
                    index: 2,
                    name: 'APPLICATION.TABS.REAL_ESTATE',
                    ref: 'realEstateList',
                    id: '#real-estate-target',
                    icon: 'fas fa-home',
                    show: true,
                },
                {
                    index: 3,
                    name: 'APPLICATION.TABS.SIGN',
                    ref: 'signatureDetails',
                    id: '#signature-details-target',
                    icon: 'fas fa-pencil-alt',
                    show: true,
                },
                {
                    index: 4,
                    name: 'APPLICATION.TABS.SUMMARY',
                    ref: 'applicationSummary',
                    id: '#summary',
                    icon: 'fas fa-check-circle',
                    show: true,
                },
            ];
        },
        isLoadingSave() {
            return this.loading['applications/update'];
        },
        viewMode() {
            return !this.isDraft;
        },
        isDraft() {
            return this.record?.status === 'draft';
        },
        isPaid() {
            return this.record?.is_paid;
        },
        isSigned() {
            return this.record?.is_signed;
        },
        paymentSuccessful() {
            return this.paymentStatus === 'successful';
        },
        signValidatedAndApply() {
            return this.loading['applications/signValidatedAndApply'] || this.loading['applications/finalize'] ;
        },
        showLoader() {
            return (
                this.loading['applications/pay'] ||
                this.loading['applications/initializeOfflineSign'] ||
                this.isLoading ||
                this.isLoadingSign ||
                this.isLoadingSaveDraft ||
                this.signingLoading
            );
        },
        offlineSigningText() {
            return this.loading['applications/initializeOfflineSign'] ? this.$t('APPLICATION.NOTIFIERS.GENERATE_OFFLINE_PACKAGE') : '';
        },
        disablePayment() {
            if (this.record) {
                return !this.signDetails.isOnline && !this.record.sign_details.isOnline && !this.record.is_signed;
            }
            return false;
        },
        canApply() {
            return this.isPaid && this.isSigned && this.isDraft;
        }
    },
    methods: {
        ...mapActions({
            doFind: 'applications/form/find',
            doClear: 'applications/form/clear',
            doUpdate: 'applications/form/update',
            doUpload: 'applications/form/upload',
            doPayApplication: 'applications/form/pay',
            initializeOfflineSigning: 'applications/form/initializeOfflineSigning',
            sendApplication: 'applications/form/finalizeApplication',
            doEnrollment: 'applications/form/enrollPerson',
            signDoc: 'applications/form/signDocumentCitizen',
            checkSigningStatus: 'applications/form/checkSigningStatus',
            updateOfflineSignDownloadStatus: 'applications/form/updateOfflineSignDownloadStatus',
            inactivateLoading: 'shared/setInactiveLoading'
        }),
        navItemClass(tab) {
            if (tab?.disabled) return '';
            if (this.id) return 'nav-item--success';
            return 'nav-item--warning';
        },
        goToPage(tab) {
            if (tab?.link) {
                this.$router.push({
                    name: tab.link
                });
            }
        },
        async generateData(asDraft) {
            const mainRef = this.$refs.applicationDetails.$refs;
            const genFormData = await mainRef.generateForm.getData(asDraft);

            const {options, form} = genFormData;
            let formLables = {};

            for (let item in options) {
                const opts = options[item].options;
                if (opts?.length && typeof opts[0] === 'object') {
                    const selectedOption = opts.find(opt => opt.value === form[item]);
                    if (selectedOption) {
                        formLables[`${item}_label`] = selectedOption.label;
                    }
                }
            }

            const formWithOptions = {
                form: {
                    ...formLables,
                    ...form,
                    options,
                }
            };

            const formData = {
                ...formWithOptions,
                applicant_user: {
                    first_name: this.currentUser.first_name,
                    last_name: this.currentUser.last_name,
                    email: this.currentUser.email,
                    phone_number: this.currentUser.phone_number,
                },
            };

            return formData;
        },
        async saveAsDraft(asDraft, redirect) {
            const data = await this.generateData(asDraft);
            data.status = 'draft';
            data.sign_details = this.signDetails;

            const identificationFiles = [];
            let attachedFiles = [];
            if (data.form?.attachments?.length) {
                const formData = new FormData();
                let needUpload = false;
                for (let i = 0; i < data.form?.attachments?.length; i++) {
                    const file = data.form.attachments[i];
                    if (file instanceof File) {
                        formData.append('files', file);
                        needUpload = true;
                    } else {
                        identificationFiles.push(file);
                    }
                }
                if (needUpload) {
                    const uploadedFiles = await this.doUpload({
                        files: formData,
                        uploadAccessToken: this.uploadAccessToken,
                    });
                    attachedFiles = uploadedFiles.map((el) => ({
                        file_id: el.id,
                        filename: el.initialFilename,
                        created_date: el.created_date,
                        file_type: 'input',
                        created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`
                    }));
                }
                delete data.form.attachments;
            }
            const payload = {
                id: this.record.id,
                values: {
                    ...data,
                    identification_files: [
                        ...identificationFiles,
                        ...attachedFiles,
                    ],
                },
            };

            const application = await this.doUpdate(payload);

            Message.success('APPLICATION.NOTIFIERS.DRAFT_SAVED_SUCCESS');

            if (redirect) {
                this.$router.push({
                    name: 'applications'
                });
            } else {
                return application;
            }
        },
        async doPay() {
            //need to check if the user has enough signatures
            if (this.signDetails.isOnline && this.needPackageValidation && !this.signDetails.package) {
                Message.error('APPLICATION.NOTIFIERS.CHOOSE_SIGN_PACKAGE');
                return;
            }

            if (!this.signDetails.isOnline) {
                //check if signed, if it's offline option selected
                if (this.record && !this.record.is_signed) {
                    Message.error('APPLICATION.NOTIFIERS.OFFLINE_SIGN_FAILED');
                    return;
                }
            }

            //update application
            const application = await this.saveAsDraft(false);
            const payload = {
                id: application.id,
                data: {
                    amount: 1,
                    currency: this.$t('GENERAL.CURRENCY_LABEL'),
                    summary: [],
                }
            }

            if (this.signDetails.isOnline && this.signDetails.package) {
                payload.data.summary.push({
                    id: this.signDetails.package.id,
                    name: this.signDetails.package.name,
                    type: 'CERTSIGN',
                    amount: this.signDetails.package.price_including_vat ?? 0,
                    signatures: this.signDetails.package.quantity ?? 0
                });
            }

            this.doPayApplication(payload).then((data) => {
                if (data?.bt_payment_form_url) {
                    window.location.replace(data.bt_payment_form_url);
                }
            })
        },
        handleEvent(callback) {
            if (this[callback]) {
                this[callback]();
            }
        },
        async doSign() {
            let enrollmentRequest = {
                user_id: this.currentUser.user_id,
                email: this.currentUser.email,
                first_name: this.currentUser.first_name,
                last_name: this.currentUser.last_name,
                id_card_number: this.currentUser.id_card_number,
                id_card_serial: this.currentUser.id_card_serial,
                id_card_expiration_date: this.currentUser.id_card_expiration_date,
                id_card_issuer: this.currentUser.id_card_issuer,
                cnp: this.currentUser.personal_numeric_code,
                phone_number: this.currentUser.phone_number,
                county: this.currentUser.county,
                type: 0,
            };

            const showModalInnerMethod = () => {
                this.showConfirmSignModal = true;
                this.$nextTick(() => {
                    this.$refs['confirm-sign-document-modal'].show();
                });
            };

            if (this.currentUser.enrolled) {
                try {
                    await this.signDoc(this.record.id);
                    showModalInnerMethod();
                } catch (err) {
                    if (RE_ENROLL_ERRORS.includes(err)) {
                        await this.doEnrollment({...enrollmentRequest, force: true})
                        await this.signDoc(this.record.id);
                        showModalInnerMethod();
                        return;
                    }

                    Errors.handle(err);
                }

                return;
            }

            await this.doEnrollment(enrollmentRequest);
            await this.signDoc(this.record.id);
            showModalInnerMethod();
        },
        doApply() {
            this.sendApplication(this.id).then((data) => {
                this.$router.push({
                    name: 'applicationConfirmation',
                    params: {
                        id: data.identification_number
                    }
                });
            }).catch(() => {
                this.inactivateLoading('applications/signValidatedAndApply');
            });
        },
        signValidated() {
            this.doApply();
        },
        goToSection(href, e) {
            if (e) e.stopPropagation();
            let $el;
            if (href === 'top') {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else if (this.$refs.applicationDetails.$refs[href]) {
                $el = this.$refs.applicationDetails.$refs[href].$el;
            } else if (this.$refs.applicationDetails.$refs.generateForm.$refs[href]) {
                $el = this.$refs.applicationDetails.$refs.generateForm.$refs[href][0].$el;
            }
            if ($el) {
                const elementPosition = $el.offsetTop;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
            }
        },
        updateScroll() {
            this.scrollPosition = window.scrollY;
        },
        updateSignatureType(isOnline) {
            this.signDetails = {
                isOnline,
            };
        },
        updateCertSignPackages(item) {
            this.signDetails = {
                ...this.signDetails,
                package: item,
            };
        },
        validatePackage(need) {
            this.needPackageValidation = need;
        },
        handleFormMounted() {
            this.$nextTick(() => {
                // Redirected from payment then scroll to summary section after all components are rendered
                if (this.$route.query && this.$route.query.state && this.$route.query.state === 'redirect') {
                    const el = document.querySelector('#summary');
                    el && el.scrollIntoView();
                }
            });
        },
        handleAppSummaryMounted() {
            this.$nextTick(() => {
                // Redirected from view mode then scroll to summary section after the summary section is mounted
                if (this.$route.params.tabRef) {
                    setTimeout(() => {
                        this.goToSection(this.$route.params.tabRef);
                    }, 500);
                }
            });
        },
        async doFindRecord() {
            await this.doFind({
                id: this.id,
                loadStaff: this.hasPermissionToReadStaff,
            });
        },
        async checkSignConfirmation(id) {
            try {
                await this.checkSigningStatus(id);
                if (this.signingStatus !== 'pending') {
                    clearInterval(this.polling);
                    await this.doFindRecord();
                    this.signingLoading = false;
                }
            } catch (error) {
                clearInterval(this.polling);
            }
        },
        clearPollingScraps() {
            if (this.polling) {
                clearInterval(this.polling);
            }
        },
        handleOpenLoader() {
            Swal.fire({
                icon: 'info',
                iconHtml: `<i class="fas fa-info-circle"></i>`,
                title: this.$t('APPLICATION.SIGN_AND_APPLY_LOADER.TITLE'),
                text: this.$t('APPLICATION.SIGN_AND_APPLY_LOADER.TEXT'),
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    container: 'st-alert-container',
                    actions: 'apply-loader-container',
                },
                didOpen: () => {
                    Swal.showLoading();
                },
            });
        },
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    async created() {
        if (this.id && !this.record) {
            await this.doFindRecord();
        }

        if (this.id) {
            await this.checkSigningStatus(this.id);
            if (this.signingStatus === 'pending') {
                this.polling = setInterval(() => {
                    this.checkSignConfirmation(this.id);
                }, 1000);
            } else {
                this.signingLoading = false;
            }
        }

        if (!this.id) {
            await this.doClear();
        }

        if (this.record?.sign_details) {
            this.signDetails = this.record.sign_details;
        }
        if (this.record?.archiveData?.archives) this.updateOfflineSignDownloadStatus(true);
    },
    beforeDestroy() {
        this.inactivateLoading('applications/signValidatedAndApply');
        Swal.close();
        this.clearPollingScraps();
    },
    destroy() {
        window.removeEventListener('scroll', this.updateScroll);
    },
};
</script>
<style scoped>
@import '~quill/dist/quill.snow.css';

/* Used to fake the scroll to select the first breadcrumb navbar item on pageload
and scroll to the first item because we don't have the detalis section */
#fake-scroll-top {
    margin-top: -1px;
    height: 1px;
}
</style>
